import {
  AUTH_USER_ERROR,
  AUTH_USER_SUCCESS,
  AUTH_USER_REQUEST,
  AUTH_USER_LOGOUT,
  GET_OAUTH_CLIENTS_REQUEST,
  GET_OAUTH_CLIENTS_ERROR,
  GET_OAUTH_CLIENTS_SUCCESS,
  CREATE_OAUTH_CLIENTS_SUCCESS,
  REMOVE_OAUTH_CLIENTS_SUCCESS,
  CREATE_OAUTH_CLIENTS_REQUEST,
  REMOVE_OAUTH_CLIENTS_REQUEST,
  AuthState,
  OauthClient,
  ClientDelete,
  AuthActionsTypes,
} from '../types/auth';
import { UPDATE_PROFILE_REQUEST_SUCCESS } from '../types/agents';
import * as storage from '../../services/storage';

const deleteClient = (clientData: ClientDelete) => (client: OauthClient) => (
  client.client_id !== clientData.clientId && client.client_secret !== clientData.clientSecret
);

export const initialState: AuthState = {
  token: '',
  username: '',
  firstName: '',
  lastName: '',
  builderId: 0,
  enterpriseId: null,
  builder: '',
  isRental: false,
  isPM: false,
  isAdmin: false,
  isEnterprise: false,
  isManagement: false,
  error: '',
  email: '',
  phone: '',
  send_email: false,
  send_sms: false,
  loading: false,
  oauthClients: [],
  userRoleId: 0,
};

export default function AuthReducer(
  state = initialState,
  action: AuthActionsTypes,
): AuthState {
  switch (action.type) {
    case AUTH_USER_REQUEST:
    case CREATE_OAUTH_CLIENTS_REQUEST:
    case REMOVE_OAUTH_CLIENTS_REQUEST:
    case GET_OAUTH_CLIENTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case AUTH_USER_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        username: action.payload.username,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        builderId: action.payload.builderId,
        enterpriseId: action.payload.enterpriseId,
        builder: action.payload.builder,
        isRental: action.payload.isRental,
        isPM: action.payload.isPM,
        isAdmin: action.payload.isAdmin,
        isEnterprise: action.payload.isEnterprise,
        isManagement: action.payload.isManagement,
        userRoleId: action.payload.userRoleId,
        email: action.payload.email,
        phone: action.payload.phone,
        send_email: action.payload.send_email,
        send_sms: action.payload.send_sms,
        error: '',
        loading: false,
      };
    }

    case GET_OAUTH_CLIENTS_ERROR:
    case AUTH_USER_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case GET_OAUTH_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        oauthClients: action.payload.items,
      };
    }

    case CREATE_OAUTH_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        oauthClients: state.oauthClients.concat(action.payload),
      };
    }

    case REMOVE_OAUTH_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        oauthClients: state.oauthClients.filter(deleteClient(action.payload)),
      };
    }

    case AUTH_USER_LOGOUT: {
      return initialState;
    }

    case UPDATE_PROFILE_REQUEST_SUCCESS: {
      const data = JSON.parse(storage.getItem('auth_user'));
      const updatedUser = {
        firstName: action.payload.firstName ?? data.firstName,
        lastName: action.payload.lastName ?? data.lastName,
        email: action.payload.email ?? data.email,
        phone: action.payload.phone ?? data.phone,
        send_email: action.payload.send_email ?? data.send_email,
        send_sms: action.payload.send_sms ?? data.send_sms,
        builder: action.payload.builder ?? data.builder,
      };


      storage.addItem('auth_user', JSON.stringify({
        ...data,
        ...updatedUser,
      }));

      return {
        ...state,
        ...updatedUser,
        error: '',
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
